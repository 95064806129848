<template>
  <message-incoming v-bind="{...$props, ...$attrs}" v-on="$listeners">
    <div class="bubble image-bubble" ref="imageRef">
      <v-img
        :aspect-ratio="16 / 9"
        ref="image"
        :src="src"
        :cover="cover"
        :contain="contain"
      />
    </div>
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndUp ? '940px' : '90%'"
    >
      <v-img :src="src"/>
    </v-dialog>
  </message-incoming>
</template>

<script>
import util from "../../plugins/utils";
import MessageIncoming from './MessageIncoming.vue';

export default {
  extends: MessageIncoming,
  components: { MessageIncoming },
  data() {
    return {
      src: undefined,
      cover: true,
      contain: false,
      dialog: false
    };
  },
  methods: {
    // listen for custom hammerJs singletab click to differentiate it from double click(heart animation).
    initMessageInImageHammerJs(element) {
      const hammerInstance = util.singleOrDoubleTabRecognizer(element);

      hammerInstance.on("singletap doubletap", (ev) => {
        if(ev.type === 'singletap') {
          this.dialog = true;
        }
      });
    }
  },
  mounted() {
    //console.log("Mounted with event:", JSON.stringify(this.event.getContent()));
    const width = this.$refs.image.$el.clientWidth;
    const height = (width * 9) / 16;
    util
      .getThumbnail(this.$matrix.matrixClient, this.event, this.$config, width, height)
      .then((url) => {
        const info = this.event.getContent().info;
        // JPEGs use cover, PNG and GIF ect contain. This is because PNG and GIF are expected to
        // be stickers and small emoji type things.
        if (info && info.mimetype && info.mimetype.startsWith("image/jp")) {
          this.cover = true;
          this.contain = false;
        } else {
          this.cover = false;
          this.contain = true;
        }
        this.src = url;
        if(this.$refs.imageRef) {
          this.initMessageInImageHammerJs(this.$refs.imageRef);
        }
      })
      .catch((err) => {
        console.log("Failed to fetch thumbnail: ", err);
      });
  },
  beforeDestroy() {
    if (this.src) {
      const objectUrl = this.src;
      this.src = null;
      URL.revokeObjectURL(objectUrl);
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>