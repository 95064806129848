var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('message-outgoing',_vm._g(_vm._b({},'message-outgoing',Object.assign({}, _vm.$props, _vm.$attrs),false),_vm.$listeners),[_c('div',{staticClass:"bubble poll-bubble"},[_c('div',{staticClass:"poll-icon"},[_c('v-icon',{attrs:{"light":""}},[_vm._v("$vuetify.icons.poll")])],1),_c('div',{staticClass:"poll-question"},[_vm._v(_vm._s(_vm.pollQuestion))]),_c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":""}},[_vm._l((_vm.pollAnswers),function(answer){return _c('v-row',{key:answer.id,class:{
          'poll-answer': true,
          selected: !_vm.userHasVoted && answer.id == _vm.pollTentativeAnswer,
          result: _vm.userHasVoted || _vm.pollIsClosed || _vm.pollViewResults,
          winner: answer.winner && (_vm.userHasVoted || _vm.pollIsClosed || _vm.pollViewResults),
        },attrs:{"ma-0":"","pa-0":""},on:{"click":function($event){return _vm.pollAnswer(answer.id)}}},[_c('v-col',{staticClass:"ma-0 pa-0 poll-answer-title",attrs:{"cols":"auto"}},[_vm._v(_vm._s(answer.text)+" "+_vm._s(answer.max))]),(answer.id == _vm.pollTentativeAnswer)?_c('v-col',{staticClass:"ma-0 pa-0 poll-answer-title",attrs:{"cols":"auto"}},[_c('v-img',{staticClass:"poll-check-icon",attrs:{"src":require("@/assets/icons/ic_check.svg")}})],1):_vm._e(),(
            _vm.pollIsClosed || (_vm.pollIsDisclosed && _vm.userHasVoted) || (_vm.pollIsAdmin && (_vm.userHasVoted || _vm.pollViewResults))
          )?_c('v-col',{staticClass:"ma-0 pa-0 poll-answer-num-votes",attrs:{"cols":"auto"}},[_vm._v(_vm._s(answer.percentage)+"%")]):_vm._e(),(
            _vm.pollIsClosed || (_vm.pollIsDisclosed && _vm.userHasVoted) || (_vm.pollIsAdmin && (_vm.userHasVoted || _vm.pollViewResults))
          )?_c('div',{staticClass:"poll-percent-indicator"},[_c('div',{staticClass:"bar",style:({ width: ((answer.percentage) + "%") })})]):_vm._e()],1)}),(_vm.pollTentativeAnswer)?_c('v-row',{staticClass:"pa-0 poll-status",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"auto"}},[_c('div',{staticClass:"poll-submit clickable",on:{"click":function($event){$event.stopPropagation();return _vm.pollAnswerSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("poll_create.poll_submit"))+" ")])])],1):_c('v-row',{staticClass:"pa-0 poll-status"},[_c('v-col',{staticClass:"ma-0 pa-0 poll-status-title"},[_vm._v(" "+_vm._s(_vm.$t("poll_create.num_answered", { count: _vm.pollNumAnswers }))+" ")]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"auto"}},[(!_vm.pollIsClosed && _vm.userCanClosePoll && !_vm.userHasVoted)?_c('div',{class:{ 'poll-disclose': true, clickable: true, selected: _vm.pollViewResults, 'mb-4': true }},[_vm._v(" "+_vm._s(_vm.$t("poll_create.view_results"))+" "),_c('div',{staticClass:"poll-disclose-track",on:{"click":function($event){$event.stopPropagation();_vm.pollViewResults = !_vm.pollViewResults}}},[_c('div',{staticClass:"poll-disclose-knob"})])]):_vm._e(),(_vm.userCanClosePoll && (_vm.userHasVoted || _vm.pollViewResults) && !_vm.pollIsClosed)?_c('div',{staticClass:"poll-status-close clickable",on:{"click":function($event){$event.stopPropagation();return _vm.pollClose.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("poll_create.close_poll"))+" ")]):(_vm.pollIsAdmin && _vm.pollIsClosed)?_c('div',{staticClass:"poll-status-closed clickable",on:{"click":function($event){$event.stopPropagation();return _vm.pollClose.apply(null, arguments)}}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/icons/ic_check_small.svg")}}),_vm._v(" "+_vm._s(_vm.$t("poll_create.poll_status_closed"))+" ")]):_vm._e()])],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }