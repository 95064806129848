
<template>
  <div>
    <slot
      v-if="err"
      name="error"
      v-bind:err="err"
    ><div class="text-center">{{ $t('message.failed_to_render') }}</div></slot>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  name: "MessageErrorHandler",
  data() {
    return {
      err: false,
    };
  },
  errorCaptured(err, ignoredvm, ignoredinfo) {
    this.err = err;
    return false;
  }
};
</script>

<style lang="scss" scoped>
</style>