export default {
  data() {
    return {
      languages: [],
      activeLang:null,
      displayLanguage: ['en','bo','zh_Hans','ug']
    }
  },
  methods: {
    getLanguages() {
      return this.languages;
    },
    updateLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.commit('setLanguage', lang);
    }
  },
  mounted() {
    const context = this
    for (const locale of Object.keys(this.$i18n.messages)) {
      this.languages.push({
        text: this.$i18n.messages[locale].language_display_name || locale,
        value: locale,
        display: context.displayLanguage.includes(locale)
      });
    }
  }
}