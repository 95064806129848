<template>
  <v-container fluid v-if="room">
    <v-row class="chat-header-row flex-nowrap">
      <v-col
        cols="auto"
        class="chat-header-members text-start ma-0 pa-0"
      >
        <v-avatar size="48" class="clickable me-2 chat-header-avatar rounded-circle" color="grey" @click.stop="onAvatarClicked">
          <v-img v-if="privatePartyAvatar(40)" :src="privatePartyAvatar(40)" />
          <span v-else class="white--text headline">{{
            privateParty.name.substring(0, 1).toUpperCase()
          }}</span>
        </v-avatar>
      </v-col>

      <v-col class="chat-header-name ma-0 pa-0 flex-shrink-1 flex-grow-1 flex-nowrap" @click.stop="onHeaderClicked">
        <div class="room-title-row">
          <div class="room-name-inline text-truncate" :title="privateParty.name">
            {{ privateParty.name }}
          </div>
          <v-icon v-if="$matrix.joinedAndInvitedRooms.length > 1" class="icon-dropdown" size="11">$vuetify.icons.ic_dropdown</v-icon>
          <div v-if="$matrix.joinedAndInvitedRooms.length > 1 && notifications" :class="{ 'notification-alert': true, 'popup-open': showMissedItemsInfo }">
             <v-icon class="icon-circle" size="11">circle</v-icon>
              <!-- MISSED ITEMS POPUP -->
              <!-- <div class="missed-items-popup-background" v-if="showMissedItemsInfo" @click.stop="setHasShownMissedItemsHint()"></div>             -->
            <div class="missed-items-popup" v-if="showMissedItemsInfo" @click.stop="setHasShownMissedItemsHint()">
              <div class="text">{{ notificationsText }}</div>
              <div class="button clickable" @click.stop="setHasShownMissedItemsHint()">{{$t('menu.ok')}}</div>
            </div>
          </div>
        </div>
        <div class="num-members">
          <div v-if="roomIsEncrypted" class="private-chat"><v-icon color="#616161">$vuetify.icons.ic_lock</v-icon>{{ $t("room_welcome.direct_private_chat") }}</div>
        </div>
      </v-col>

      <v-col v-if="$matrix.joinedRooms.length > 1" cols="auto" class="text-end ma-0 pa-0 ms-1">
        <v-avatar :class="{ 'avatar-32': true, 'clickable': true, 'popup-open': showProfileInfo }" size="26"
          color="#e0e0e0" @click.stop="showProfileInfo = true">
          <img v-if="userAvatar" :src="userAvatar" />
          <span v-else class="white--text">{{ userAvatarLetter }}</span>
        </v-avatar>
      </v-col>

      <v-col cols="auto" class="text-end ma-0 pa-0 ms-1">
        <v-btn id="btn-purge-room" v-if="userCanPurgeRoom" class="mx-2 box-shadow-none" fab dark small color="red"
          @click.stop="$emit('purge')">
          <v-icon light>$vuetify.icons.ic_moderator-delete</v-icon>
        </v-btn>
        <template v-else>
          <v-btn v-if="$matrix.joinedRooms.length == 1" id="btn-leave-room" class="box-shadow-none leave-button" color="red" @click.stop="leaveRoom">
            <v-icon color="white">$vuetify.icons.ic_member-leave</v-icon>{{ $t('room.leave') }}
          </v-btn>
          <v-btn id="btn-leave-room" class="mx-2 box-shadow-none" fab dark small color="red" @click.stop="leaveRoom" v-else>
            <v-icon color="white">$vuetify.icons.ic_member-leave</v-icon>
          </v-btn>
        </template>
      </v-col>
      <v-col v-if="$matrix.joinedRooms.length > 1" cols="auto" class="text-end ma-0 pa-0 ms-1 clickable close-button more-menu-button">
        <div :class="{ 'popup-open': showMoreMenu }">
          <v-btn class="mx-2 box-shadow-none" fab dark small color="transparent" @click.stop="onShowMoreMenu">
            <v-icon size="15">$vuetify.icons.ic_more</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- "REALLY LEAVE?" dialog -->
    <LeaveRoomDialog :show="showLeaveConfirmation" :room="room" @close="showLeaveConfirmation = false" />

    <!-- PROFILE INFO POPUP -->
    <ProfileInfoPopup :show="showProfileInfo" @close="showProfileInfo = false" />

    <!-- MORE MENU POPUP -->
    <MoreMenuPopup :show="showMoreMenu" :menuItems="moreMenuItems" @close="showMoreMenu = false"
      v-on:leave="showLeaveConfirmation = true" />

    <RoomExport :room="room" v-if="downloadingChat" v-on:close="downloadingChat = false" />

  </v-container>
</template>

<script>
import LeaveRoomDialog from "../components/LeaveRoomDialog";
import ProfileInfoPopup from "../components/ProfileInfoPopup";
import MoreMenuPopup from "../components/MoreMenuPopup";
import RoomExport from "../components/RoomExport";

import ChatHeader from "./ChatHeader.vue";

export default {
  name: "ChatHeaderPrivate",
  extends: ChatHeader,
  components: {
    LeaveRoomDialog,
    ProfileInfoPopup,
    MoreMenuPopup,
    RoomExport
  },
};
</script>


<style lang="scss">
@import "@/assets/css/chat.scss";

.popup-open {
  position: relative;
  overflow: visible;
  color: white;
}

.popup-open::after {
  position: absolute;
  left: 50%;
  // Need to move the "more items" arrow to the left, since it's too close to the edge
  // and would interfere with the dialog rounding...
  .more-menu-button & {
    left: calc(50% - 4px);
  }
  content: " ";
  top: 42px;
  margin-left: -10px;
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 2px;
  background-color: currentColor;
  z-index: 400;
  pointer-events: none;
  animation-duration: 0.3s;
  animation-delay: 0.2s;
  animation-fill-mode: both;
  animation-name: fadein;
  animation-iteration-count: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.private-chat {
  display: flex;
  align-items: center;
}

.private-chat .v-icon {
  width: 9px;
  height: 11px;
  margin-right: 6px;
}

</style>