import { render, staticRenderFns } from "./RoomTypeSelector.vue?vue&type=template&id=5049470c&"
import script from "./RoomTypeSelector.vue?vue&type=script&lang=js&"
export * from "./RoomTypeSelector.vue?vue&type=script&lang=js&"
import style0 from "./RoomTypeSelector.vue?vue&type=style&index=0&id=5049470c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSelect})
