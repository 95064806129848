import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import Chat from '../components/Chat.vue'
import Join from '../components/Join.vue'
import Login from '../components/Login.vue'
import Profile from '../components/Profile.vue'
import CreateRoom from '../components/CreateRoom.vue'
import GetLink from '../components/GetLink.vue'
import CreateChannel from '../components/CreateChannel.vue'
import User from '../models/user'
import util from '../plugins/utils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/room/:roomId?',
    name: 'Chat',
    component: Chat,
    meta: {
      includeRoom: true,
      includeFavicon: true
    }
  },
  {
    path: '/info',
    name: 'RoomInfo',
    component: () => import('../components/RoomInfo.vue'),
    props: true,
    meta: {
      title: 'Info',
      includeRoom: true,
      includeFavicon: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
      includeFavicon: true
    }
  },
  {
    path: '/createroom',
    name: 'CreateRoom',
    component: CreateRoom,
    meta: {
      title: 'Create room'
    }
  },
  {
    path: '/getlink',
    name: 'GetLink',
    component: GetLink,
  },
  {
    path: '/createchannel',
    name: 'CreateChannel',
    component: CreateChannel,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    props: true
  },
  {
    path: '/join/:roomId?',
    name: 'Join',
    component: Join
  },
  {
    path: '/user/:userId?',
    name: 'User',
    component: Join
  },
  {
    path: '/invite/:roomId?',
    name: 'Invite',
    component: () => import('../components/Invite.vue'),
    meta: {
      title: 'Add Friends'
    }
  },
  {
    path: '/goodbye',
    name: 'Goodbye',
    component: () => import('../components/QuoteView.vue'),
    props: true
  }
]

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/createroom', '/getlink', '/createchannel'];
  var authRequired = !publicPages.includes(to.path);
  const loggedIn = router.app.$store.state.auth.user;

  if (to.query && to.query.lang) {
    // Set language via query param
    const lang = to.query.lang;
    // Check if valid translation
    if (router.app.$i18n.messages[lang]) {
      router.app.$store.commit('setLanguage', lang);
      if (router.app.$i18n) {
        router.app.$i18n.locale = to.query.lang;
      }
    }
  }

  if (to.name == 'Chat' || to.name == 'Join') {
    if (!to.params.roomId && to.hash) {
      // Public rooms start with '#', confuses the router. If hash but no roomId param, set it.
      to.params.roomId = to.hash;
    }
    const roomId = util.sanitizeRoomId(to.params.roomId);
    router.app.$matrix.setCurrentRoomId(roomId);
    if (roomId && roomId.startsWith('#')) {
      //Invite to public room
      authRequired = false;
    }
  } else if (to.name == 'User') {
    if (to.params.userId) {
      let roomId = util.sanitizeUserId(to.params.userId);
      if (roomId && !roomId.startsWith("@")) {
        // Not a full username. Assume local name on this server.
        return router.app.$config.promise.then((config) => {
          const domain = config.defaultMatrixDomainPart;
          if (!domain) throw new Error("No domain part for user invite!");
          roomId = "@" + roomId + ":" + domain;  
          router.app.$matrix.setCurrentRoomId(roomId);
        }).catch(err => console.error(err)).finally(() => next());
      } else {
        router.app.$matrix.setCurrentRoomId(roomId);
        authRequired = false;
      }
    }
  } else if (to.name == 'Invite') {
    if (to.params.roomId) {
      const roomId = util.sanitizeRoomId(to.params.roomId);
      router.app.$matrix.setCurrentRoomId(roomId);
    }
  } else if (to.name == 'CreateRoom') {
    return router.app.$config.promise.then((config) => {
      if (config.hide_add_room_on_home) {
        next('/');
      } else {
        next();
      }
    }).catch(err => { console.error(err); next('/'); });
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

router.getRoomLink = function (alias, roomId, roomName, mode) {
  let params = {};
  if ((!alias || roomName.replace(/\s/g, "").toLowerCase() !== util.getRoomNameFromAlias(alias)) && roomName) {
    // There is no longer a correlation between alias and room name, probably because room name has
    // changed. Include the "?roomName" part
    params["roomName"] = roomName;
  }
  if (mode) {
    // Optional mode given, append as "m" query param
    params["m"] = mode;
  }
  if (Object.entries(params).length > 0) {
    const queryString = Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
    return window.location.origin + window.location.pathname + "?" + queryString + "#/room/" + encodeURIComponent(util.sanitizeRoomId(alias || roomId));
  }
  return window.location.origin + window.location.pathname + "#/room/" + encodeURIComponent(util.sanitizeRoomId(alias || roomId));
}

router.getDMLink = function (user, config) {
  let userId = user.user_id;
  if (User.domainPart(userId) === config.defaultMatrixDomainPart && !config.useFullyQualifiedDMLinks) {
    // Using default server, don't include it in the link
    userId = User.localPart(user.user_id);
  }
  return `${window.location.origin + window.location.pathname}#/user/${encodeURIComponent(userId)}`
}

export default router
