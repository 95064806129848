<template>
  <div class="join-room" v-if="!waitingForInfo && !waitingForMembership">
    <div class="join-enter-room">
      <div>
        <div class="text-center">
          <v-avatar class="join-avatar">
            <v-img v-if="roomAvatar" :src="roomAvatar" />
            <span v-else class="white--text headline">
              {{ roomName.substring(0, 1).toUpperCase() }}
            </span>
          </v-avatar>
          <div class="join-welcome font-weight-bold">
            {{ roomId && roomId.startsWith("@") ? $t("join.title_user") : $t("join.title") }}
          </div>
          <div class="join-title">
            {{ roomDisplayName || roomName }}
          </div>
        </div>

        <hr class="my-10 join-line" />
        <div class="font-weight-black mb-4" v-if="!currentUser">Choose a name to use.</div>

        <v-row v-if="canEditProfile">
          <v-col cols="10" sm="7" class="py-0">
            <v-select
              ref="avatar"
              :items="availableAvatars"
              cache-items
              outlined
              dense
              @change="selectAvatar"
              :value="availableAvatars[0]"
              single-line
              autofocus
            >
              <template v-slot:selection>
                <v-text-field
                  background-color="transparent"
                  solo
                  flat
                  hide-details
                  @click.native.stop="
                    {
                    }
                  "
                  v-model="selectedProfile.name"
                ></v-text-field>
              </template>
              <template v-slot:item="data">
                <v-avatar size="32">
                  <v-img :src="data.item.image" />
                </v-avatar>
                <div class="ms-2">{{ data.item.name }}</div>
              </template>
            </v-select>
            <v-checkbox
              id="chk-remember-me"
              class="mt-0"
              v-model="rememberMe"
              :label="$t('join.remember_me')"
              @change="onRememberMe"
            />
          </v-col>
          <v-col cols="2" sm="5" class="py-0">
            <v-avatar @click="showAvatarPicker">
              <v-img v-if="selectedProfile" :src="selectedProfile.image" />
            </v-avatar>
          </v-col>
        </v-row>
        <v-row v-else class="text-center">
          <v-col>
            {{ $t("join.joining_as") }}
            <div class="d-inline-block">
              <v-avatar color="#e0e0e0">
                <v-img v-if="userAvatar" :src="userAvatar" />
                <span v-else class="white--text headline">{{ userAvatarLetter }}</span>
              </v-avatar>
            </div>
            {{ userDisplayName }}
          </v-col>
        </v-row>

        <interactive-auth ref="interactiveAuth" />

        <v-btn id="btn-join" class="btn-dark" :disabled="room && room.selfMembership == 'ban'" large @click.stop="handleJoin" :loading="loading" v-if="!currentUser">{{
          roomId && roomId.startsWith("@") ? $t("join.enter_room_user") : $t("join.enter_room")
        }}</v-btn>
        <v-btn id="btn-join" class="btn-dark" :disabled="room && room.selfMembership == 'ban'" large block @click.stop="handleJoin" :loading="loading" v-else>{{
          roomId && roomId.startsWith("@") ? $t("join.join_user") : $t("join.join")
        }}</v-btn>

        <div v-if="loadingMessage" class="text-center">{{ loadingMessage }}</div>
        <div v-if="room && room.selfMembership == 'ban'" class="text-center">{{ $t("join.you_have_been_banned") }}</div>
      </div>
    </div>

    <input
      id="room-avatar-picker"
      ref="avatar"
      type="file"
      name="avatar"
      @change="handlePickedAvatar($event)"
      accept="image/*"
      class="d-none"
    />

    <div class="join-lang">
      <h3 class="mb-2">{{ $t("profile.select_language") }}</h3>
      <v-row class="align-center">
        <v-col v-for="lang in activeLanguage" :key="lang.value">
          <span class="active">{{ lang.text }}</span>
        </v-col>
        <v-col v-for="(lang, key) in getDisplayLanguage.slice(0, 3)" :key="key">
          <button class="inactive" @click="updateLanguage(lang.value)">{{ lang.text }}</button>
        </v-col>
        <v-col>
          <v-btn id="btn-select-language" class="language-more" @click="showSelectLanguageDialog = true">
            <v-icon>language</v-icon><v-icon>more_horiz</v-icon>
          </v-btn>
        </v-col>
        <SelectLanguageDialog v-model="showSelectLanguageDialog" v-on:close="showSelectLanguageDialog = false" />
      </v-row>
    </div>

    <div class="d-flex justify-center align-center mt-9">
      <div class="mr-2">
        <img :src="logotype" width="32" height="32" contain class="d-inline" />
      </div>
      <div>
        <strong>{{ $t("project.name") }}</strong>
        {{ $t("project.tag_line") }}
      </div>
    </div>
  </div>

 <!-- Loading indicator -->
 <v-container
   v-else
      fluid
      fill-height
      class="loading-indicator transparent"
    >
    <v-row align="center" justify="center">
      <v-col class="text-center">
        <interactive-auth ref="interactiveAuth" v-if="waitingForRoomCreation" />
        <v-progress-circular v-else
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import util from "../plugins/utils";
import InteractiveAuth from './InteractiveAuth.vue';
import LanguageMixin from "./languageMixin";
import rememberMeMixin from "./rememberMeMixin";
import logoMixin from "./logoMixin";
import SelectLanguageDialog from "./SelectLanguageDialog.vue";

export default {
  name: "Join",
  mixins: [LanguageMixin, rememberMeMixin, logoMixin],
  components: {
    SelectLanguageDialog,
    InteractiveAuth
  },
  data() {
    return {
      roomName: null,
      roomAvatar: null,
      loading: false,
      loadingMessage: null,
      waitingForInfo: true,
      waitingForMembership: false,
      waitingForRoomCreation: false,
      availableAvatars: [],
      selectedProfile: null,
      showEditDisplaynameDialog: false,
      showSelectLanguageDialog: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    room() {
      return this.$matrix.currentRoom;
    },
    roomId() {
      if (this.room) {
        return this.room.roomId;
      }
      return this.$matrix.currentRoomId;
    },
    roomAliasOrId() {
      if (this.room) {
        return this.room.getCanonicalAlias() || this.room.roomId;
      }
      return this.$matrix.currentRoomId;
    },

    canEditProfile() {
      // If we have an account already, we can't edit profile here (need to go into profile view)
      if (this.currentUser) {
        return false;
      }
      return true;
    },

    userDisplayName() {
      return this.$matrix.currentUserDisplayName;
    },

    userAvatar() {
      if (!this.$matrix.userAvatar) {
        return null;
      }
      return this.$matrix.matrixClient.mxcUrlToHttp(this.$matrix.userAvatar, 80, 80, "scale", true);
    },

    userAvatarLetter() {
      if (!this.currentUser || !this.currentUser.userId) {
        return null;
      }
      return (this.currentUserDisplayName || this.currentUser.userId.substring(1)).substring(0, 1).toUpperCase();
    },
    getDisplayLanguage() {
      let displayLanguages = [...this.getLanguages()];
      return displayLanguages.filter((lang) => lang.display && lang.value !== this.$i18n.locale);
    },
    activeLanguage() {
      let activeLanguages = [...this.getLanguages()];
      return activeLanguages.filter((lang) => lang.value === this.$i18n.locale);
    },
    roomDisplayName() {
      // If there is a display name in to invite link, use that!
      try {
        return new URL(location.href).searchParams.get('roomName');
      } catch(ignoredError) {
        return undefined;
      }
    }
  },
  watch: {
    roomId: {
      immediate: true,
      handler(value, oldVal) {
        if (!value || (value && value == oldVal)) {
          return; // No change.
        }
        console.log("Join: Current room changed to " + (value ? value : "null"));
        this.roomName = this.removeHomeServer(this.roomId);

        this.waitingForInfo = true;
        this.waitingForRoomCreation = false;
        const self = this;
        this.waitingForMembership = true;
        if (this.currentUser) {
          this.$matrix
            .getLoginPromise()
            .then(() => {
              self.$matrix.setCurrentRoomId(self.roomAliasOrId); // Go to this room, now or when joined.
              const room = self.$matrix.getRoom(self.roomAliasOrId);

              // Already joined?
              if (room && room.hasMembershipState(self.currentUser.user_id, "join")) {
                // Yes, go to room
                self.$navigation.push(
                  {
                    name: "Chat",
                    params: { roomId: util.sanitizeRoomId(this.roomAliasOrId) },
                  },
                  -1
                );
                return;
              }
            })
            .catch((err) => {
              console.log("Error logging in: ", err);
            })
            .finally(() => {
              this.waitingForMembership = false;
              this.getRoomInfo();
            });
        } else {
          this.waitingForMembership = false;
          this.getRoomInfo();
        }
      },
    },
    activeLanguage() {
      // Null status on language change, we don't know what status we are in so we can't get the right string.
      this.loadingMessage = null;
    },
  },
  methods: {
    removeHomeServer(roomName) {
      let lastIndex = roomName.lastIndexOf(":");
      if (roomName && roomName.startsWith("@")) {
        return roomName.substring(roomName.indexOf("@") + 1, lastIndex);
      }
      if (roomName && roomName.startsWith("#")) {
        return roomName.substring(roomName.indexOf("#") + 1, lastIndex);
      }
      return roomName ? roomName : "";
    },
    getRoomInfo() {
      if (this.roomId.startsWith("#")) {
        this.$matrix
          .getPublicRoomInfo(this.roomId)
          .then((room) => {
            console.log("Found room:", room);
            this.roomName = this.removeHomeServer(room.name);
            this.roomAvatar = room.avatar;
          })
          .catch((err) => {
            console.log("Could not find room info", err);
          })
          .finally(() => {
            this.waitingForInfo = false;
          });
      } else if (this.roomId.startsWith("@")) {
        // Direct chat with user
        this.waitingForRoomCreation = true;
        this.$nextTick(() => {
          this.handleJoin();
        });
      } else {
        // Private room, try to get name
        const room = this.$matrix.getRoom(this.roomId);
        if (room) {
          this.roomName = this.removeHomeServer(room.name || this.roomName);
        } else {
          this.roomName = this.removeHomeServer(this.roomAliasOrId);
        }
        this.waitingForInfo = false;
      }
    },

    onMyMembership(room, membership, ignoredprevMembership) {
      if (room && room.roomId == this.roomId && membership == "join") {
        this.$nextTick(() => {
          this.$navigation.push(
            {
              name: "Chat",
              params: { roomId: util.sanitizeRoomId(this.roomAliasOrId) },
            },
            -1
          );
        });
      }
    },

    handleOpenApp() {
      console.log("Open app..."); //TODO
    },

    handleJoin() {
      this.loading = true;
      this.loadingMessage = this.$t("join.status_logging_in");
      const hasUser = this.currentUser ? true : false;
      var setProfileData = false;
      return this.$matrix
        .getLoginPromise(this.$refs.interactiveAuth.registrationFlowHandler)
        .then(
          function (user) {
            if (user.is_guest && !hasUser) {
              // Newly created account, joining first room.
              // Set avatar and display name to either the randomly chosen ones, or the
              // ones the users has changed to.
              setProfileData = true;

              // Set display name and avatar directly on the matrix object.
              if (this.selectedProfile.name && this.selectedProfile.name.length > 0) {
                this.$matrix.userDisplayName = this.selectedProfile.name;
              }
            }

            if (!setProfileData || !this.selectedProfile.name || this.selectedProfile.name.length == 0) {
              return Promise.resolve(user);
            } else {
              console.log("Join: Set display name to: " + this.selectedProfile.name);
              return this.$matrix.setUserDisplayName(this.selectedProfile.name);
            }
          }.bind(this)
        )
        .then(
          function () {
            if (!setProfileData || !this.selectedProfile.image) {
              console.log("Join: No avatar change");
              return Promise.resolve("no avatar");
            } else {
              console.log("Join: Updating avatar");
              return util.setAvatar(this.$matrix, this.selectedProfile.image, function (progress) {
                console.log("Progress: " + JSON.stringify(progress));
              });
            }
          }.bind(this)
        )
        .then((ignoreduser) => {
          if (this.roomId.startsWith("@")) {
            // start direct chat with user
            return this.$matrix.getOrCreatePrivateChat(this.roomId).then((room) => {
              this.$matrix.setCurrentRoomId(room.roomId);
              return room;
            });
          } else {
            console.log("Join: joining room");
            this.$analytics.event("Invitations", "Room Joined");
            this.loadingMessage = this.$t("join.status_joining");
            return this.$matrix.matrixClient.joinRoom(this.roomId);
          }
        })
        .then((room) => {
          this.loading = false;
          this.loadingMessage = null;
          this.$nextTick(() => {
            this.$navigation.push(
              {
                name: "Chat",
                params: { roomId: util.sanitizeRoomId(room.roomId) },
              },
              -1
            );
          });
        })
        .catch((err) => {
          // TODO - handle error
          console.log("Failed to join room", err);
          this.loading = false;
          this.loadingMessage = this.$t("join.join_failed");
          this.waitingForInfo = false;
          this.waitingForMembership = false;
        });
    },

    selectAvatar(value) {
      this.selectedProfile = Object.assign({}, value); // Make a copy, so editing does not destroy data
    },

    showAvatarPicker() {
      this.$refs.avatar.click();
    },

    handlePickedAvatar(event) {
      util.loadAvatarFromFile(event, (image) => {
        this.selectedProfile.image = image;
      });
    },
  },
  mounted() {
    this.$matrix.on("Room.myMembership", this.onMyMembership);
    this.availableAvatars = util.getDefaultAvatars();
    this.selectAvatar(this.availableAvatars[Math.floor(Math.random() * this.availableAvatars.length)]);
    // mounted() is called more than once, so multiple instances of this event
    // do not necessarily indicate a user was shown the join page more than once.
    // To analyze this data, segment users into those who have an "Invitations"
    // action and set up conversion tracking triggered by Room Joined (once per visit).
    this.$analytics.event("Invitations", "Join Page Shown");
  },
  destroyed() {
    this.$matrix.off("Room.myMembership", this.onMyMembership);
  },
};
</script>

<style lang="scss">
@import "@/assets/css/join.scss";
</style>
