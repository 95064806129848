<template>
  <message-incoming v-bind="{...$props, ...$attrs}" v-on="$listeners">
    <div class="bubble audio-bubble">
      <audio-player :event="event" :timelineSet="timelineSet">{{ $t('fallbacks.audio_file')}}</audio-player>
    </div>
  </message-incoming>
</template>

<script>
import MessageIncoming from './MessageIncoming.vue';
import AudioPlayer from './AudioPlayer.vue';

export default {
  extends: MessageIncoming,
  components: { MessageIncoming, AudioPlayer },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>