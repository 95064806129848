<template>
  <div style="text-align: center;">
  <div class="created-room-welcome-header">
    <div class="mt-2" v-if="privateParty">{{ $t("room_welcome.direct_info", { you: $matrix.currentUserDisplayName, user: privateParty.name }) }}</div>
  </div>
</div>
</template>

<script>
import roomInfoMixin from "../roomInfoMixin";

export default {
  name: "WelcomeHeaderDirectChat",
  mixins: [roomInfoMixin],
  computed: {
    roomHistoryDescription() {
      const visibility = this.$matrix.getRoomHistoryVisibility(this.room);
      switch (visibility) {
        case "world_readable":
          return this.$t("room_welcome.room_history_is", {
            type: this.$t("message.room_history_world_readable"),
          });
        case "shared":
          return this.$t("room_welcome.room_history_is", {
            type: this.$t("message.room_history_shared"),
          });
        case "invited":
          return this.$t("room_welcome.room_history_is", {
            type: this.$t("message.room_history_invited"),
          });
        case "joined":
          return this.$t("room_welcome.room_history_joined");
      }
      return null;
    },
  },
  data() {
    return {
      publicRoomLinkCopied: false
    }
  },
  methods: {
    copyPublicLink() {
      const self = this;
      this.$copyText(this.publicRoomLink).then(
        function (ignored) {
          // Success!
          self.publicRoomLinkCopied = true;
          setInterval(() => {
            // Hide again
            self.publicRoomLinkCopied = false;
          }, 3000);
        },
        function (e) {
          console.log(e);
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>