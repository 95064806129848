<template>
  <v-dialog v-model="showDialog" content-class="more-menu-popup" class="ma-0 pa-0">
    <div class="popup-wrapper">
    <v-card flat>
      <v-card-text>

        <v-container class="mt-0 pa-0 pt-3 pb-3 action-row-container-no-dividers">
          <ActionRow v-for="item in menuItems" :key="item.name" :icon="item.icon" :iconSize="16" :text="item.text" @click="$emit('close');item.handler()" />

          <v-row v-if="showProfile" class="profile-row clickable" @click="viewProfile" no-gutters align-content="center">
            <v-col cols="auto" class="me-2">
              <v-avatar class="avatar-32" size="32" color="#e0e0e0" @click.stop="viewProfile">
                <img v-if="userAvatar" :src="userAvatar" />
                <span v-else class="white--text">{{ userAvatarLetter }}</span>
              </v-avatar>
            </v-col>
            <v-col>
              <div class="profile-label">{{ $t('profile.title') }}</div>
              <div class="display-name">{{ displayName }}</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
  </v-dialog>
</template>
<script>
import profileInfoMixin from "./profileInfoMixin";
import ActionRow from "./ActionRow.vue";

export default {
  name: "MoreMenuPopup",
  mixins: [profileInfoMixin],
  components: { ActionRow },
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    showProfile: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    menuItems: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      showDialog: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(newVal, ignoredOldVal) {
        this.showDialog = newVal;
      },
    },
    showDialog() {
      if (!this.showDialog) {
        this.$emit("close");
      }
    },
  },

  methods: {
    viewProfile() {
      this.showDialog = false;
      this.$navigation.push({ name: "Profile" }, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
@import '~vuetify/src/styles/settings/_variables.scss';

.popup-wrapper {
  width: fit-content;
  background: rgba(255, 255, 255, 0.98);
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 18px;
  pointer-events: initial;
  overflow: hidden;
}

.more-menu-popup {
  font-family: "Inter", sans-serif !important;
  font-size: 16px;
  line-height: 117%;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;
  position: fixed;
  margin: 0px;
  top: 70px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  box-shadow: none;
  pointer-events: none;
  .v-card__text {
    padding: 0 !important;
  }

  .action-row {
    height: 40px;
    padding: 4px 20px !important;
    font-size: 16px;
    color: #000B16;
  }

  .profile-row {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px 20px 8px 20px !important;
  }

  .action-row:after {
    display: none !important;
  }

  .profile-label {
    letter-spacing: 0.4px;
    color: #000B16;
  }

  .display-name {
    font-size: 13px;
    letter-spacing: 0.4px;
    color: #000B16;
  }

  [dir="rtl"] & {
    right: inherit;
    left: 10px;
  }

  //border-radius: 40px;
  width: 95%;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    width: 70%;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    overflow: unset;
    width: $main-desktop-width;
    ;
    position: absolute;
    top: 70px;
    right: unset;
    width: $dialog-desktop-width;

    &::before {
      position: absolute;
      top: -18px;
      right: 40px;
    }

    // .v-card {
    //   border-radius: 20px;
    // }
  }
}
</style>