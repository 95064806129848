<template>
  <div :class="{'message-operations':true,'incoming':incoming,'outgoing':!incoming}">
    <template v-for="(item,index) in getEmojis">
      <v-btn v-if="userCanSendReactionAndAnswerPoll && index < maxRecents" :key="item.data" id="btn-quick-reaction" icon @click.stop="addQuickReaction(item.data)" class="ma-0 pa-0" dense>
        <span class="recent-emoji">{{ item.data }}</span>
      </v-btn>
    </template>
    <v-btn v-if="userCanSendReactionAndAnswerPoll" id="btn-more" icon @click.stop="more" class="ma-0 pa-0">
      <v-icon small> $vuetify.icons.addReaction </v-icon>
    </v-btn>
    <v-btn v-if="userCanSendMessage" id="btn-reply" icon @click.stop="addReply" class="ma-0 pa-0">
      <v-icon>reply</v-icon>
    </v-btn>
    <v-btn id="btn-edit" icon @click.stop="edit" class="ma-0 pa-0" v-if="isEditable">
      <v-icon small>edit</v-icon>
    </v-btn>
    <v-btn id="btn-redact" icon @click.stop="redact" class="ma-0 pa-0" v-if="isRedactable">
      <v-icon small>delete</v-icon>
    </v-btn>
    <v-btn id="btn-download" icon @click.stop="download" class="ma-0 pa-0" v-if="isDownloadable">
      <v-icon small>get_app</v-icon>
    </v-btn>
  </div>
</template>

<script>
import messageMixin from "./messageMixin";
import messageOperationsMixin from "./messageOperationsMixin";

export default {
  mixins: [messageMixin, messageOperationsMixin],
  data() {
    return {
      maxRecents: 3
    }
  },
  props: {
    emojis: {
      type: Array,
      default: function () {
        return []
      }
    },
    userCanSendMessage: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    userCanSendReactionAndAnswerPoll: {
      type: Boolean,
      default: function() {
        return true;
      }
    }
  },
  computed: {
    getEmojis() {
      return this.emojis.slice(0,2);
    }
  },

  watch: {
    emojis: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log("Emojis changed", newVal, oldVal);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>