<template>
  <!-- Contact left the chat -->
  <div class="messageJoin">
    {{ $t('message.user_left',{user: eventStateKeyDisplayName(event)}) }}
  </div>
</template>

<script>
import messageMixin from "./messageMixin";

export default {
  mixins: [messageMixin],
  mounted() {
    if(this.room.getJoinedMemberCount() === 0) {
      this.$navigation.push({ name: "Home", params: { roomId: null } }, -1);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>