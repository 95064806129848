<template>
  <div class="home">
    <YouAre class="mt-4"  />
    <v-container fluid class="text-center mt-8">
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="auto">
          <v-img contain :src="logotype" width="64" height="64" />
        </v-col>
      </v-row>
    </v-container>

    <v-card class="members ma-3" flat>
      <v-card-title class="h2">{{ $t("room.room_list_rooms") }}</v-card-title>
      <v-card-text class="pa-0">
        <RoomList
          showInvites
          :showCreate="!$config.hide_add_room_on_home"
          v-on:newroom="createRoom"
        />
      </v-card-text>
    </v-card>

    <!-- Loading indicator -->
    <v-container
      fluid
      fill-height
      v-if="loading"
      class="loading-indicator"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RoomList from "../components/RoomList";
import YouAre from "../components/YouAre.vue";
import logoMixin from "../components/logoMixin";
export default {
  components: {
    RoomList,
    YouAre,
  },
  mixins: [logoMixin],
  computed: {
    loading() {
      return !this.$matrix.ready;
    },
  },
  methods: {
    logout() {
      //TODO - For guest accounts, show warning about not being able to rejoin.
      this.$store.dispatch("logout");
      this.$nextTick(() => {
        this.$navigation.push({ path: "/login" }, -1);
      });
    },

    createRoom() {
      this.$navigation.push({ name: "CreateRoom" });
    },
  },
};
</script>

<style lang="scss">
</style>