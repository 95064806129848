<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <div class="bubble image-bubble">
      <v-img :aspect-ratio="16 / 9" ref="image" :src="src" :cover="cover" :contain="contain" />
    </div>
  </message-outgoing>
</template>

<script>
import MessageOutgoing from "../MessageOutgoing.vue";

export default {
  name: "MessageOutgoingImageExport",
  extends: MessageOutgoing,
  components: { MessageOutgoing },
  data() {
    return {
      src: null,
      cover: true,
      contain: false,
    };
  },
  mounted() {
    const info = this.event.getContent().info;
    // JPEGs use cover, PNG and GIF ect contain. This is because PNG and GIF are expected to
    // be stickers and small emoji type things.
    if (info && info.mimetype && info.mimetype.startsWith("image/jp")) {
      this.cover = true;
      this.contain = false;
    } else {
      this.cover = false;
      this.contain = true;
    }
  },
  beforeDestroy() {
    if (this.src) {
      const objectUrl = this.src;
      this.src = null;
      URL.revokeObjectURL(objectUrl);
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
