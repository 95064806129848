<template>
  <message-incoming v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <div class="bubble image-bubble">
      <v-responsive :aspect-ratio="16 / 9" :src="src">
        <video controls class="w-100 h-100">
          {{ $t("fallbacks.video_file") }}
        </video>
      </v-responsive>
    </div>
  </message-incoming>
</template>

<script>
import exportedAttachmentMixin from "./exportedAttachmentMixin";
import MessageIncoming from "../MessageIncoming.vue";

export default {
  name: "MessageIncomingVideoExport",
  extends: MessageIncoming,
  components: { MessageIncoming },
  mixins: [exportedAttachmentMixin],
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
