<template>
  <!-- Contact left the chat -->
  <div class="messageJoin">
    {{ (event.getStateKey() == this.$matrix.currentUserId) ?
    $t('message.user_was_kicked_you')
    :
    event.getSender() == this.$matrix.currentUserId ?
    $t('message.user_was_kicked_by_you',{user: eventStateKeyDisplayName(event)})
    :
    $t('message.user_was_kicked',{user: eventStateKeyDisplayName(event)})
    }}
  </div>
</template>

<script>
import messageMixin from "./messageMixin";

export default {
  mixins: [messageMixin],
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>