<template>
  <message-outgoing v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <div class="bubble poll-bubble">
      <div class="poll-icon">
        <v-icon light>$vuetify.icons.poll</v-icon>
      </div>

      <div class="poll-question">{{ pollQuestion }}</div>
      <v-container fluid ma-0 pa-0>
        <v-row
          v-for="answer in pollAnswers"
          :key="answer.id"
          @click="pollAnswer(answer.id)"
          :class="{
            'poll-answer': true,
            selected: !userHasVoted && answer.id == pollTentativeAnswer,
            result: userHasVoted || pollIsClosed || pollViewResults,
            winner: answer.winner && (userHasVoted || pollIsClosed || pollViewResults),
          }"
          ma-0
          pa-0
        >
          <v-col cols="auto" class="ma-0 pa-0 poll-answer-title">{{ answer.text }} {{ answer.max }}</v-col>
          <v-col v-if="answer.id == pollTentativeAnswer" cols="auto" class="ma-0 pa-0 poll-answer-title"
            ><v-img class="poll-check-icon" src="@/assets/icons/ic_check.svg"
          /></v-col>
          <v-col
            v-if="
              pollIsClosed || (pollIsDisclosed && userHasVoted) || (pollIsAdmin && (userHasVoted || pollViewResults))
            "
            cols="auto"
            class="ma-0 pa-0 poll-answer-num-votes"
            >{{ answer.percentage }}%</v-col
          >
          <div
            v-if="
              pollIsClosed || (pollIsDisclosed && userHasVoted) || (pollIsAdmin && (userHasVoted || pollViewResults))
            "
            class="poll-percent-indicator"
          >
            <div class="bar" :style="{ width: `${answer.percentage}%` }"></div>
          </div>
        </v-row>
        <v-row v-if="pollTentativeAnswer" class="pa-0 poll-status" justify="center">
          <v-col class="ma-0 pa-0" cols="auto"
            ><div class="poll-submit clickable" @click.stop="pollAnswerSubmit">
              {{ $t("poll_create.poll_submit") }}
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="pa-0 poll-status">
          <v-col class="ma-0 pa-0 poll-status-title">
            {{ $t("poll_create.num_answered", { count: pollNumAnswers }) }}
          </v-col>
          <v-col cols="auto" class="ma-0 pa-0">
            <div
              :class="{ 'poll-disclose': true, clickable: true, selected: pollViewResults, 'mb-4': true }"
              v-if="!pollIsClosed && userCanClosePoll && !userHasVoted"
            >
              {{ $t("poll_create.view_results") }}

              <div class="poll-disclose-track" @click.stop="pollViewResults = !pollViewResults">
                <div class="poll-disclose-knob"></div>
              </div>
            </div>
            <div
              class="poll-status-close clickable"
              v-if="userCanClosePoll && (userHasVoted || pollViewResults) && !pollIsClosed"
              @click.stop="pollClose"
            >
              {{ $t("poll_create.close_poll") }}
            </div>
            <div class="poll-status-closed clickable" v-else-if="pollIsAdmin && pollIsClosed" @click.stop="pollClose">
              <img class="icon" src="@/assets/icons/ic_check_small.svg" />
              {{ $t("poll_create.poll_status_closed") }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </message-outgoing>
</template>

<script>
import pollMixin from "./pollMixin";
import MessageOutgoing from "./MessageOutgoing.vue";

export default {
  extends: MessageOutgoing,
  mixins: [pollMixin],
  components: { MessageOutgoing },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
@import "@/assets/css/components/poll.scss";
</style>
