<template>
  <div v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <!-- No attachments view -->
    <template v-if="!attachments || attachments.length == 0">
      <div>
        <v-icon>$vuetify.icons.ic_lock</v-icon>
        <div class="file-drop-title">{{ $t("file_mode.secure_file_send") }}</div>
      </div>
      <div :class="{ 'background': true, 'drop-target': dropTarget }" @drop.prevent="filesDropped"
        @dragover.prevent="dropTarget = true" @dragleave.prevent="dropTarget = false"
        @dragenter.prevent="dropTarget = true">
        <v-btn @click="$emit('pick-file')" class="large">{{ $t("file_mode.choose_files") }}</v-btn>
        <div class="file-format-info">{{ $t("file_mode.any_file_format_accepted") }}</div>
      </div>
    </template>

    <!-- ATTACHMENT SELECTION MODE -->
    <template v-if="attachments && attachments.length > 0 && status == mainStatuses.SELECTING">
      <div class="attachment-wrapper" ref="attachmentWrapper">
        <div :class="{ 'file-drop-current-item': true, 'drop-target': dropTarget }" @drop.prevent="filesDropped"
          @dragover.prevent="dropTarget = true" @dragleave.prevent="dropTarget = false"
          @dragenter.prevent="dropTarget = true">
          <v-img v-if="currentItemHasImagePreview" :src="attachments[currentItemIndex].image" />
          <div v-else class="filename">{{ attachments[currentItemIndex].name }}</div>
        </div>
        <div class="file-drop-thumbnail-container">
          <div :class="{ 'file-drop-thumbnail': true, 'clickable': true, 'current': id == currentItemIndex }"
            @click="currentItemIndex = id" v-for="(currentImageInput, id) in attachments" :key="id">
            <v-img v-if="currentImageInput && currentImageInput.image" :src="currentImageInput.image" />
            <div v-if="currentItemIndex == id" class="remove clickable" @click.stop="$emit('remove-file', id)">
              <v-icon>$vuetify.icons.ic_trash</v-icon>
            </div>
          </div>
          <div class="file-drop-thumbnail noborder">
            <div class="add clickable" @click.stop="$emit('pick-file')">
              +
            </div>
          </div>
        </div>
        <div class="file-drop-input-container">
          <v-textarea ref="input" full-width solo flat auto-grow v-model="messageInput" no-resize class="input-area-text"
            rows="1" :placeholder="$t('file_mode.add_a_message')" hide-details background-color="transparent"
            v-on:keydown.enter.prevent="() => {
              sendCurrentTextMessage();
            }
              " />
          <v-btn @click="sendAll" :disabled="!attachments || attachments.length == 0">{{ $t("menu.send") }}</v-btn>
        </div>
      </div>
    </template>

    <!-- ATTACHMENT SENDING/SENT MODE -->
    <template
      v-if="attachments && attachments.length > 0 && (status == mainStatuses.SENDING || status == mainStatuses.SENT)">
      <div class="attachment-wrapper">
        <div class="file-drop-sent-stack" ref="stackContainer">
          <div v-if="status == mainStatuses.SENDING && attachmentsSentCount == 0" class="no-items">
            <div class="file-drop-stack-item direct" :style="stackItemTransform(null, -1)"></div>
            <div>{{ $t('file_mode.sending_progress') }}</div>
          </div>
          <div v-else v-for="(info, index) in attachmentsSent" :key="info.id" class="file-drop-stack-item animated"
            :style="stackItemTransform(info, index)">
            <v-img v-if="info.preview" :src="info.preview" />
          </div>
          <div v-if="status == mainStatuses.SENT" class="items-sent" :style="stackItemTransform(null, -1)">
            <v-icon>$vuetify.icons.ic_check_circle</v-icon>
          </div>
        </div>

        <!-- Middle section -->
        <div v-if="status == mainStatuses.SENDING" class="file-drop-sending-container">
          <div class="file-drop-sending-item" v-for="(info, index) in attachmentsSending" :key="index">
            <v-img v-if="info.preview" :src="info.preview" />
            <div v-else class="filename">{{ info.attachment.name }}</div>
            <v-progress-linear :value="info.progress"></v-progress-linear>
            <div class="file-drop-cancel clickable" @click.stop="cancelSendAttachmentItem(info)">
              <v-icon size="14" color="white">close</v-icon>
            </div>
          </div>
        </div>
        <div v-else-if="status == mainStatuses.SENT" class="file-drop-sending-container">
          <div class="file-drop-files-sent">{{ $tc((this.messageInput && this.messageInput.length > 0) ?
            "file_mode.files_sent_with_note" : "file_mode.files_sent", attachmentsSent.length) }}</div>
          <div class="file-drop-section">
            <v-textarea disabled full-width solo flat auto-grow v-model="messageInput" no-resize class="input-area-text"
              rows="1" hide-details background-color="transparent" />
          </div>
        </div>

        <!-- Bottom section -->
        <div v-if="status == mainStatuses.SENDING" class="file-drop-sending-input-container">
          <v-textarea disabled full-width solo flat auto-grow v-model="messageInput" no-resize class="input-area-text"
            rows="1" :placeholder="$t('file_mode.add_a_message')" hide-details background-color="transparent" />
          <v-btn>{{ $t("file_mode.sending") }}<v-progress-circular indeterminate size="18" width="2"
              color="#4642F1"></v-progress-circular></v-btn>
        </div>
        <div v-else-if="status == mainStatuses.SENT" class="file-drop-sent-input-container">
          <v-btn @click.stop="reset">{{ $t("file_mode.send_more_files") }}</v-btn>
          <v-btn class="close" @click.stop="close">{{ $t("file_mode.close") }}</v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import messageMixin from "../messages/messageMixin";
import sendAttachmentsMixin from "../sendAttachmentsMixin";
const prettyBytes = require("pretty-bytes");

export default {
  mixins: [messageMixin, sendAttachmentsMixin],
  components: {},
  props: {
    attachments: {
      type: Array,
      default: function () {
        return []
      }
    },
  },
  data() {
    return {
      currentItemIndex: 0,
      messageInput: "",
      mainStatuses: Object.freeze({
        SELECTING: 0,
        SENDING: 1,
        SENT: 2,
      }),
      status: 0,
      dropTarget: false,
    };
  },
  mounted() {
    document.body.classList.add("dark");
    this.$audioPlayer.setAutoplay(false);
  },
  beforeDestroy() {
    document.body.classList.remove("dark");
  },
  computed: {
    currentItemHasImagePreview() {
      return this.currentItemIndex >= 0 && this.currentItemIndex < this.attachments.length &&
        this.attachments[this.currentItemIndex].image
    },
  },
  watch: {
    attachments(newValue, oldValue) {
      // Added or removed?
      if (newValue && oldValue && newValue.length > oldValue.length) {
        this.currentItemIndex = oldValue.length;
      } else if (newValue) {
        this.currentItemIndex = newValue.length - 1;
      }
    },
    messageInput() {
      this.scrollToBottom();
    },
  },
  methods: {
    filesDropped(e) {
      this.dropTarget = false;
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      ([...droppedFiles]).forEach(f => {
        this.$emit('add-file', f);
      });
    },
    scrollToBottom() {
      const el = this.$refs.attachmentWrapper;
      if (el) {
        // Ugly - need to wait until input is auto-sized, THEN scroll to bottom.
        //
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.$nextTick(() => {
              el.scrollTop = el.scrollHeight
            })
          })
        })
      }
    },
    formatBytes(bytes) {
      return prettyBytes(bytes);
    },
    reset() {
      this.$emit('reset');
      this.sendingAttachments = [];
      this.status = this.mainStatuses.SELECTING;
      this.messageInput = "";
      this.currentItemIndex = 0;
    },
    close() {
      this.$matrix.leaveRoomAndNavigate(this.room.roomId)
        .catch((err) => {
          console.log("Error leaving", err);
        });
    },
    sendAll() {
      this.status = this.mainStatuses.SENDING;
      this.sendAttachments((this.messageInput && this.messageInput.length > 0) ? this.messageInput : this.$t('file_mode.files'), this.attachments)
        .then(() => {
          this.status = this.mainStatuses.SENT;
        });
    },
    stackItemTransform(item, index) {
      const size = 0.6 * (this.$refs.stackContainer ? Math.min(this.$refs.stackContainer.clientWidth, this.$refs.stackContainer.clientHeight) : 176);
      let transform = ""
      if (item != null && index != -1) {
        transform = "transform: rotate(" + item.randomRotation + "deg) translate(" + item.randomTranslationX + "px," + item.randomTranslationY + "px); z-index:" + (index + 2) + ";";
      }
      return transform + "width:" + size + "px;height:" + size + "px;border-radius:" + (size / 8) + "px";
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>