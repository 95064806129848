<template>
  <BottomSheet
    class="room-info-bottom-sheet"
    :halfY="0.12"
    ref="sheet"
    :showCloseButton="false"
  >
    <div class="room-info-sheet" ref="roomInfoSheetContent">
      <room-list v-on:close="close" v-on:newroom="createRoom" :showCreate="!$config.hide_add_room_on_home" />
    </div>
  </BottomSheet>
</template>

<script>
import BottomSheet from "./BottomSheet";
import RoomList from "./RoomList";
import roomInfoMixin from "./roomInfoMixin";

export default {
  name: "RoomInfoBottomSheet",
  mixins: [roomInfoMixin],
  components: {
    BottomSheet,
    RoomList,
  },
  methods: {
    open() {
      this.$refs.sheet.open();
    },

    close() {
      this.$refs.sheet.close();
    },

    showDetails() {
      this.close();
      this.$navigation.push({ name: "RoomInfo" });
    },

    createRoom() {
      this.close();
      this.$navigation.push({ name: "CreateRoom" });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
