export default class User {
    constructor(user_id, password, is_guest) {
        this.user_id = user_id;
        this.password = password;
        this.is_guest = is_guest || false
    }

    static localPart(user_id) {
        if (user_id && user_id.startsWith('@') && user_id.includes(':')) {
            const parts = user_id.split(":");
            return parts[0].substring(1);
        }
        return user_id;
    }

    static domainPart(user_id) {
        if (user_id && user_id.startsWith('@') && user_id.includes(':')) {
            const parts = user_id.split(":");
            return parts[1];
        }
        return undefined;
    }
}