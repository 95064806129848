export default {
  computed: {
    showOwnJoins() {
      return !this.$matrix.isDirectRoom(this.room);
    },

    showAllStatusMessages() {
      // We say that if you can redact events, you are ad admin.
      // NOTE!!! This assumes that there is a property named "room" on THIS.
      const me = this.room && this.room.getMember(this.$matrix.currentUserId);
      let isModerator =
        me && this.room.currentState && this.room.currentState.hasSufficientPowerLevelFor("redact", me.powerLevel);
    const show =  this.$config.show_status_messages;
    return show !== "never" && (show !== "moderators" || isModerator)
    },
  },
}
  