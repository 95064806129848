export default {
  data() {
    return {
      joinedAndInvitedMembers: [],
    };
  },
  mounted() {
    this.$matrix.on("Room.timeline", this.roomMembersMixinOnEvent);
    this.updateMembers();
  },

  destroyed() {
    this.$matrix.off("Room.timeline", this.roomMembersMixinOnEvent);
  },

  computed: {
    joinedMembers() {
      return this.joinedAndInvitedMembers.filter(m => m.membership === "join");
    }
  },
  watch: {
    room: {
      handler() {
        this.updateMembers();
      },
    },
  },
  methods: {
    roomMembersMixinOnEvent(event) {
      if (this.room && this.room.roomId == event.getRoomId()) {
        // For this room
        if (event.getType() == "m.room.member") {
          this.updateMembers();
        }
      }
    },

    sortMemberFunction(a, b) {
      const myUserId = this.$matrix.currentUserId;

      // Place ourselves at the top!
      if (a.userId == myUserId) {
        return -1;
      } else if (b.userId == myUserId) {
        return 1;
      }

      // Then sort by power level
      if (a.powerLevel > b.powerLevel) {
        return -1;
      } else if (b.powerLevel > a.powerLevel) {
        return 1;
      }

      // Then by name
      const aName = a.user ? a.user.displayName : a.name;
      const bName = b.user ? b.user.displayName : b.name;
      return aName.localeCompare(bName);
    },

    updateMembers() {
      if (this.room) {
        this.joinedAndInvitedMembers = this.room.getMembers().sort(this.sortMemberFunction);
      } else {
        this.joinedAndInvitedMembers = [];
      }
    },
  },
};
