import { render, staticRenderFns } from "./GalleryItemsView.vue?vue&type=template&id=05756d12&"
import script from "./GalleryItemsView.vue?vue&type=script&lang=js&"
export * from "./GalleryItemsView.vue?vue&type=script&lang=js&"
import style0 from "./GalleryItemsView.vue?vue&type=style&index=0&id=05756d12&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VIcon,VImg})
