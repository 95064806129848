<template>
  <v-select outlined dense :items="availableRoomTypes" 
    :value="modelValue"
    @change="$emit('update:modelValue', $event)" 
    :reduce="(obj) => obj.value">
    <template v-slot:selection="{ item }">{{ item.title }}</template>
    <template v-slot:item="{ item, attrs, on }">
      <v-list-item v-on="on" v-bind="attrs" #default="{}">
        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import roomTypeMixin from "./roomTypeMixin";

export default {
  name: "RoomTypeSelector",
  mixins: [roomTypeMixin],
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  props: {
    modelValue: {
      type: String,
      default: function () {
        return null;
      },
    },
  }
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>
