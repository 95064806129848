<template>
  <v-dialog
    v-if="activeMember"
    v-model="showDialog"
    v-show="room"
    class="ma-0 pa-0"
    :width="$vuetify.breakpoint.smAndUp ? '688px' : '95%'"
  >
    <div class="dialog-content text-center member-action-dialog">
      <div class="pt-4">
        <v-avatar class="avatar" size="56" color="grey">
          <img v-if="memberAvatarComp" :src="memberAvatarComp" />
          <span v-else class="white--text headline">{{ firstLetterUserName }}</span>
        </v-avatar>
        <div>
          <span class="user-name">
          {{
            isCurrentUser
              ? $t("room_info.user_you", {
                  user: activeMemberNameComp
                })
              : $t("room_info.user", {
                  user: activeMemberNameComp
                })
          }}
          </span>
          <span v-if="isAdminComp" class="user-power">
            {{ $t("room_info.user_admin") }}
          </span>
          <span v-else-if="isModeratorComp" class="user-power">
            {{ $t("room_info.user_moderator") }}
          </span>
        </div>
        <template v-if="activeMember.userId != $matrix.currentUserId && sharedRooms.length">
          <span v-if="sharedRooms.length < 3">{{ $t("room_info.shared_room_number", {count: sharedRooms.length, name: activeMemberNameComp}) }}</span>
          <span v-else>{{ $t("room_info.shared_room_number_more", {count: sharedRooms.length, name: activeMemberNameComp}) }}</span>
          <p class="font-weight-light">{{ sharedRooms.slice(0, 3).join(", ") }}</p>
        </template>
      </div>
      <DeviceList :member="activeMember" />
      <div class="py-3" v-if="activeMember.userId != $matrix.currentUserId">
        <v-btn text x-large block v-if="activeMember.userId != $matrix.currentUserId && !$matrix.isDirectRoomWith(room, activeMember.userId)" class="start-private-chat clickable d-block text-none justify-start" @click="startPrivateChat(activeMember.userId)">
          <v-icon left>$vuetify.icons.direct_chat</v-icon>  {{ $t("menu.direct_chat") }}
        </v-btn>
        <div v-if="canBanUserComp">
          <v-btn text x-large block v-if="activeMember.userId != $matrix.currentUserId && canBanUserComp" class="start-private-chat clickable d-block text-none justify-start" @click="banUser(activeMember)">
            <v-icon left>$vuetify.icons.kickout</v-icon> {{ $t("menu.user_kick_and_ban") }}
          </v-btn>
        </div>
        <v-btn text x-large block v-if="activeMember.userId != $matrix.currentUserId && !isAdminComp && canMakeAdminComp" class="start-private-chat clickable d-block text-none justify-start" @click="makeAdmin(activeMember)">
          <v-icon left>$vuetify.icons.make_admin</v-icon> {{ $t("menu.user_make_admin") }}
        </v-btn>
        <v-btn text x-large block v-if="activeMember.userId != $matrix.currentUserId && !isModeratorComp && !isAdminComp && canMakeModeratorComp" class="start-private-chat clickable d-block text-none justify-start" @click="makeModerator(activeMember)">
          <v-icon left>$vuetify.icons.make_moderator</v-icon> {{ $t("menu.user_make_moderator") }}
        </v-btn>
        <v-btn text x-large block v-if="activeMember.userId != $matrix.currentUserId && isModeratorComp && canRevokeModeratorComp" class="start-private-chat clickable d-block text-none justify-start" @click="revokeModerator(activeMember)">
          <v-icon left>$vuetify.icons.revoke</v-icon> {{ $t("menu.user_revoke_moderator") }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script>
import roomInfoMixin from "./roomInfoMixin";
import DeviceList from "../components/DeviceList";
import util from "../plugins/utils";

export default {
  name: "UserProfileDialog",
  mixins: [roomInfoMixin],
  components: {
    DeviceList
  },
  props: {
    show: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    activeMember: {
      type: Object,
      default: function () {
        return null;
      }
    }
  },
  data() {
    return {
      showDialog: false
    };
  },
  computed: {
    canRevokeModeratorComp () {
      return this.canRevokeModerator(this.activeMember)
    },
    isModeratorComp() {
      return this.isModerator(this.activeMember)
    },
    canMakeModeratorComp() {
      return this.canMakeModerator(this.activeMember)
    },
    canMakeAdminComp() {
      return this.canMakeAdmin(this.activeMember)
    },
    canBanUserComp() {
      return this.canBanUser(this.activeMember)
    },
    isAdminComp() {
      return this.isAdmin(this.activeMember)
    },
    activeMemberNameComp() {
      return this.activeMember.user ? this.activeMember.user.displayName : this.activeMember.name
    },
    isCurrentUser() {
      return this.activeMember.userId == this.$matrix.currentUserId
    },
    firstLetterUserName() {
      return this.activeMember.name.substring(0, 1).toUpperCase()
    },
    memberAvatarComp() {
      return this.memberAvatar(this.activeMember)
    },
    joinedMembersByRoomId() {
      const joinedRooms = this.$matrix.joinedRooms.filter(room => !this.$matrix.isDirectRoom(room)) || [];
      return joinedRooms.map(room => ({
        roomId: room.roomId,
        roomName: room.name,
        memberIds: room.getJoinedMembers().map(({ userId }) => userId)
      }));
    },
    sharedRooms() {
      return this.joinedMembersByRoomId.reduce((sharedRooms, room) => {
        if (room.roomId !== this.room.roomId && room.memberIds.includes(this.activeMember.userId)) {
          sharedRooms.push(room.roomName);
        }
        return sharedRooms;
      }, []);
    }
  },
  watch: {
    show: {
      handler(newVal, ignoredOldVal) {
        this.showDialog = newVal;
      }
    },
    showDialog() {
      if (!this.showDialog) {
        this.$emit("close");
      }
    },
  },

  methods: {
    startPrivateChat(userId) {
      this.$matrix
        .getOrCreatePrivateChat(userId)
        .then((room) => {
          this.$nextTick(() => {
            this.$navigation.push(
              {
                name: "Chat",
                params: {
                  roomId: util.sanitizeRoomId(
                    room.getCanonicalAlias() || room.roomId
                  ),
                },
              },
              -1
            );
          });
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.showDialog = false;
        });
    },
    canBanUser(member) {
      if (this.room) {
        const myUserId = this.$matrix.currentUserId;
        const me = this.room.getMember(myUserId);
        return me && me.powerLevelNorm > member.powerLevelNorm && this.room.currentState && this.room.currentState.hasSufficientPowerLevelFor("ban", me.powerLevel);
      }
      return false;
    },
    /**
     * Return true if WE can make the member an admin
     * @param  member
     */
    canMakeAdmin(ignoredmember) {
      if (this.room) {
        const myUserId = this.$matrix.currentUserId;
        const me = this.room.getMember(myUserId);
        return me && this.isAdmin(me);
      }
      return false;
    },

    /**
     * Return true if WE can make the member a moderator
     * @param  member
     */
     canMakeModerator(ignoredmember) {
      if (this.room) {
        const myUserId = this.$matrix.currentUserId;
        const me = this.room.getMember(myUserId);
        return me && this.isAdmin(me);
      }
      return false;
    },
    /**
     * Return true if WE can "unmake" the member a moderator
     * @param  member
     */
     canRevokeModerator(member) {
      if (this.room) {
        const myUserId = this.$matrix.currentUserId;
        const me = this.room.getMember(myUserId);
        return me && this.isAdmin(me) && me.powerLevel > member.powerLevel;
      }
      return false;
    },
    makeAdmin(member) {
      if (this.room) {
        this.$matrix.makeAdmin(this.room.roomId, member.userId)
        this.showDialog = false;
      }
    },
    makeModerator(member) {
      if (this.room) {
        this.$matrix.makeModerator(this.room.roomId, member.userId)
        this.showDialog = false;
      }
    },
    revokeModerator(member) {
      if (this.room) {
        this.$matrix.revokeModerator(this.room.roomId, member.userId)
        this.showDialog = false;
      }
    },
    banUser(member) {
      if (this.room) {
        this.$matrix.banUser(this.room.roomId, member.userId)
        this.showDialog = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/chat.scss";
</style>